<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Urbanisme"
        :progress_section="progress_section.urbanisme"
      />
    </template>
    <template #current_page>Urbanisme {{ is_api_zone_loading }}</template>
    <template #body>
      <div class="w-full" v-if="api_zones_urbanisme_loaded">
        <!--map -->
        <div class="flex md:flex-col">
          <div class="w-9/12 md:w-full mr-12">
            <Map
              class="h-64-screen"
              :is_zone_plu="is_zone_plu"
              :opacity_layers_plu="opacity_layers_plu"
              :all_zones_urba="urbanisme.all_zones_urba"
              ref="map_urbanisme"
            ></Map>
          </div>
          <div class="w-3/12 md:mt-8 md:w-full flex flex-col">
            <CadastreOfficiel :map="map"></CadastreOfficiel>

            <div class="rounded h-32 bg-white px-6 py-5 mt-8">
              <div class="flex justify-between items-stretch">
                <label
                  for="geoportail"
                  class="text-gray-600 text-sm capitalize"
                >
                  Zonage de PLU
                </label>
                <div class="mr-8">
                  <input
                    type="checkbox"
                    id="geoportail"
                    v-model="is_zone_plu"
                    :value="true"
                  />

                  <label for="geoportail"></label>
                </div>
              </div>
              <div class="mt-4">
                <span class="text-gray-500 text-xs">Opacité</span>
                <vue-slider
                  dotSize="12"
                  :railStyle="{ backgroundColor: '#ECECEC' }"
                  :dotStyle="{ backgroundColor: '#865af8' }"
                  :process-style="{ backgroundColor: '#B09AE9' }"
                  v-model="opacity_layers_plu"
                />
              </div>
            </div>
            <button
              class="btn-green mt-8"
              @click="updateZonesUrbaine(informationsStore, true)"
            >
              mise à jour zones
            </button>
          </div>
        </div>
        <!-- end map -->
        <!-- les infos -->
        <div>
          <div class="">
            <div class="flex flex-col">
              <div class="text-promy-gray-400 mt-8">Choisissez une Zone</div>
              <pro-switch
                class="mb-8 flex flex-wrap"
                :data="getLiebelleZones()"
                v-model="selected_zone"
                @valueSelect="changeCurrentZone($event)"
              />

              <div
                class="grid grid-cols-2 w-3/4 gap-x-6 gap-y-2 sm:grid-cols-1"
                v-if="current_zone"
              >
                <div>
                  <div class="text-sm text-gray-600 font-bold font-arial mb-2">
                    Caractère de la zone
                  </div>
                  <quill-editor
                    ref="myQuillEditor"
                    class="mb-8 w-full"
                    v-model="current_zone.properties.caractere_zone"
                  />
                  <HighlightMessage
                    :show="
                      isEmptyOrNull(current_zone.properties.caractere_zone)
                    "
                  />
                </div>

                <pro-input
                  shouldBeHighlighted
                  label="Description"
                  v-model="current_zone.properties.libelong"
                  requirements="required"
                  name="description"
                ></pro-input>

                <pro-input
                  shouldBeHighlighted
                  label="hauteur"
                  v-model="current_zone.properties.hauteur"
                  requirements="required"
                  name="hauteur"
                  type="number"
                ></pro-input>

                <pro-input
                  shouldBeHighlighted
                  label="CES"
                  v-model="current_zone.properties.ces"
                  requirements="required"
                  name="ces"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  label="CPT"
                  v-model="current_zone.properties.cpt"
                  requirements="required"
                  name="cpt"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  label="stationement"
                  v-model="current_zone.properties.stationement"
                  requirements="required"
                  name="stationement"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  label="mixité social"
                  v-model="current_zone.properties.mixite_social"
                  requirements="required"
                  name="mixité social"
                ></pro-input>

                <pro-input
                  shouldBeHighlighted
                  label="bande principale"
                  v-model="current_zone.properties.bande_principale"
                  requirements="required"
                  name="bande principale"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  label="bande secondaire"
                  v-model="current_zone.properties.bande_secondaire"
                  requirements="required"
                  name="bande secondaire"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  label="IMPL/Voie"
                  v-model="current_zone.properties.imple_vole"
                  requirements="required"
                  name="IMPL/Voie"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  label="IMPL/Limite séparative"
                  v-model="current_zone.properties.impl_limite_separative"
                  requirements="required"
                  name="IMPL/Limite separative"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  label="IMPL/Bâtiment"
                  v-model="current_zone.properties.impl_batiment"
                  requirements="required"
                  name="IMPL/Bâtiment"
                ></pro-input>
                <pro-input
                  shouldBeHighlighted
                  requirements="required"
                  name="espace vert"
                  v-model="current_zone.properties.espace_vert"
                  label="espace vert"
                />
              </div>
              <hr class="my-6 border-gray-300" />
              <div class="mb-10">
                <label
                  class="capitalize text-xs text-gray-600 font-bold font-arial"
                  >PUP
                </label>
                <div class="flex mt-1">
                  <div class="flex items-center mr-12 space-x-4">
                    <ProRadio
                      :value="'Oui'"
                      @input="urbanisme.pup = $event"
                      name="pup"
                      id="pup-oui"
                      label="Oui"
                      :checked="urbanisme.pup === 'Oui'"
                      :required="true"
                    ></ProRadio>
                    <ProRadio
                      :value="'Non'"
                      @input="urbanisme.pup = $event"
                      name="pup"
                      id="pup-non"
                      label="Non"
                      :checked="urbanisme.pup === 'Non'"
                      :required="true"
                    ></ProRadio>
                  </div>
                </div>
                <HighlightMessage
                  classes="relative top-5"
                  :show="isEmptyOrNull(urbanisme.pup)"
                />
              </div>
              <div class="my-2">
                <label
                  class="capitalize text-xs text-gray-600 font-bold font-arial"
                >
                  ABF
                </label>
                <div class="flex mt-1">
                  <div class="flex items-center mr-12 space-x-4">
                    <ProRadio
                      :value="'Oui'"
                      @input="urbanisme.abf = $event"
                      name="abf"
                      id="abf-oui"
                      label="Oui"
                      :checked="urbanisme.abf === 'Oui'"
                      :required="true"
                    ></ProRadio>
                    <ProRadio
                      :value="'Non'"
                      @input="urbanisme.abf = $event"
                      name="abf"
                      id="abf-non"
                      label="Non"
                      :checked="urbanisme.abf === 'Non'"
                      :required="true"
                    ></ProRadio>
                  </div>
                </div>
                <HighlightMessage
                  classes="relative top-5"
                  :show="isEmptyOrNull(urbanisme.abf)"
                />
              </div>
              <div class="my-2">
                <label
                  class="capitalize text-xs text-gray-600 font-bold font-arial"
                >
                  Afficher Documents d'urbanisme
                </label>
                <div class="flex mt-1">
                  <div class="flex items-center mr-12 space-x-4">
                    <ProRadio
                      :value="'Oui'"
                      @input="urbanisme.show_docs = $event"
                      name="show_docs"
                      id="show_docs-oui"
                      label="Oui"
                      :checked="urbanisme.show_docs === 'Oui'"
                      :required="true"
                    ></ProRadio>
                    <ProRadio
                      :value="'Non'"
                      @input="urbanisme.show_docs = $event"
                      name="show_docs"
                      id="show_docs-non"
                      label="Non"
                      :checked="urbanisme.show_docs === 'Non'"
                      :required="true"
                    ></ProRadio>
                  </div>
                </div>
                <HighlightMessage
                  classes="relative top-5"
                  :show="isEmptyOrNull(urbanisme.show_docs)"
                />
              </div>
            </div>
          </div>
        </div>
        <!--end les info -->
        <div class="flex flex-col w-1/2" v-if="urbanisme.show_docs === 'Non'">
          <div
            class="stats-values flex my-2"
            v-for="(attr, key) in urbanisme.articles_plu"
            :key="'article-plu-' + key"
          >
            <div class="flex flex-col flex-1 w-auto">
              <pro-input
                shouldBeHighlighted
                class="w-1/2 mr-6"
                name="Titre"
                requirements="required"
                v-model="attr.title"
                label="Titre"
              />
              <pro-textarea
                shouldBeHighlighted
                name="Contenu"
                label="Contenu"
                row="4"
                v-model="attr.text"
                requirements="required"
              ></pro-textarea>
              <HighlightMessage
                classes="relative"
                :show="isEmptyOrNull(attr.text)"
              />
            </div>
            <div class="w-1/12 flex items-start pb-3">
              <span
                @click="deleteArticle(key)"
                class="text-promy-red ml-4 mr-1 cursor-pointer"
                v-tooltip.right="{
                  content: 'Supprimer cet attribut',
                  classes: ['px-2 text-promy-gray-600'],
                }"
              >
                <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
              </span>
            </div>
          </div>
          <div class="mt-3">
            <span
              class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-base"
              @click="addArticle()"
            >
              <p>Ajouter un champ</p>
              <span
                class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-2 py-2 px-2"
              >
                <i class="fa fa-plus text-sm"></i>
              </span>
            </span>
          </div>
        </div>
        <div class="my-6" v-if="urbanisme.show_docs === 'Oui'">
          <table-title>
            <template v-slot:title>Les PDFs</template>
          </table-title>
          <div>
            <div
              class="w-50 h-33 relative border border-dashed border-promy-gray-400 rounded flex items-center justify-center mt-2"
            >
              <div class="text-promy-gray-400 text-3xl">
                <i class="fa fa-upload fa-6" aria-hidden="true"></i>
              </div>
              <input
                ref="fileinput"
                @change.prevent="handleFileChange"
                class="w-full absolute opacity-0 h-full inset-0 cursor-pointer"
                type="file"
              />
            </div>
          </div>
          <button class="btn-green mt-8 px-6" @click="getPdsThroughApi()">
            Recharger les documents d'origine
          </button>
          <div class="flex flex-col w-2/3 sm:w-full md:w-2/3 mt-6">
            <div class="flex bg-promy-gray-200 py-2 rounded-t-lg">
              <div class="w-1/4">
                <p class="text-sm text-gray-700 capitalize text-center">
                  nom de fichier
                </p>
              </div>
              <div class="w-1/4">
                <p class="text-sm text-gray-700 capitalize text-center">
                  Dernière mise à jour
                </p>
              </div>
              <div class="w-1/4">
                <p class="text-sm text-gray-700 capitalize text-center">
                  Visibilité
                </p>
              </div>
            </div>
            <div
              class="flex items-center bg-white py-3 border-t odd:bg-promy-gray-200"
              v-for="(pieceEcrite, index) in urbanisme.pdfs"
              :key="index"
            >
              <div class="w-1/4 px-2">
                <p
                  class="text-sm text-promy-green-300 font-extrabold capitalize text-center cursor-pointer underline"
                  v-if="!pieceEcrite.editText"
                  @click="openPdfNewWindow(pieceEcrite.value)"
                >
                  {{ pieceEcrite.name }}
                </p>
                <pro-input
                  class=""
                  v-else-if="pieceEcrite.editText"
                  v-model="pieceEcrite.name"
                  requirements="required"
                  name="Nom fichier"
                ></pro-input>
              </div>
              <div class="w-1/4 px-2">
                <p
                  v-if="!pieceEcrite.editText"
                  class="text-sm text-gray-700 capitalize text-center"
                >
                  {{ pieceEcrite.date }}
                </p>
                <datepicker
                  v-else-if="pieceEcrite.editText"
                  class="date-input"
                  v-model="pieceEcrite.date"
                  valueType="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                ></datepicker>
              </div>
              <div class="w-1/4 flex justify-center items-start">
                <input
                  type="checkbox"
                  :id="`checkbox-${index}`"
                  v-model="pieceEcrite.isShown"
                  :checked="pieceEcrite.isShown"
                  :value="pieceEcrite.isShown"
                />
                <label
                  :for="`checkbox-${index}`"
                  style="top: -10px; right: 10px"
                ></label>
              </div>
              <div class="w-1/4 flex justify-end pr-4">
                <div
                  v-tooltip.bottom="{
                    content: 'Voir le document',
                    classes: classes,
                  }"
                  class="rounded-full p-2 mr-3 cursor-pointer bg-promy-green-300 w-8 h-8 flex items-center justify-center"
                  @click="openPdfNewWindow(pieceEcrite.value)"
                >
                  <i class="fas fa-eye text-white text-xs sm:mr-1"></i>
                </div>

                <div
                  class="rounded-full p-2 mr-3 cursor-pointer"
                  :class="
                    pieceEcrite.editText
                      ? 'bg-white border-promy-green-300 border-2'
                      : 'bg-promy-green-300'
                  "
                  @click="
                    !pieceEcrite.editText
                      ? showPDFUpdate(pieceEcrite, index)
                      : updatePDF(pieceEcrite, index)
                  "
                >
                  <icon-update
                    :color="
                      pieceEcrite.editText
                        ? 'text-promy-green-300'
                        : 'text-white'
                    "
                    class="w-4 h-4"
                  />
                </div>
                <div
                  v-if="pieceEcrite.isUploaded"
                  class="bg-promy-green-300 rounded-full p-2 mr-3 cursor-pointer flex items-center justify-center"
                  @click="displayModal(index)"
                >
                  <img src="/images/trash.svg" alt="" class="w-4 h-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ValidateModal
          v-if="showModal"
          @isCancel="showModal = false"
          @isValide="deletePDF()"
        >
          <slot slot="message_body"
            >Êtes-vous sûr de vouloir supprimer ce pdf ?</slot
          >
          <slot slot="button_valide">Continuer</slot>
          <slot slot="button_cancel">Annuler</slot>
        </ValidateModal>
      </div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Map from './components/Map'
import helpers from '@/mixins/helpers'
import { mapGetters } from 'vuex'
import progress from '@/mixins/dossierPromoteurs/progress'
import urbanisme from '@/mixins/dossierPromoteurs/urbanisme'

// import _ from "lodash";
import moment from 'moment'

export default {
  mixins: [helpers, progress, urbanisme],
  components: {
    VueSlider,
    Map,
  },
  data() {
    return {
      current_zone: null,
      selected_zone: null,
      current_pdf_idx: null,
      showModal: false,
      is_zone_plu: true,
      opacity_layers_plu: 100,
      piecesEcrites: [],
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-sm',
        'bg-white',
        'mb-2',
      ],
    }
  },
  computed: {
    ...mapGetters({
      infos_cadastre: 'parcelle/infos_cadastre',
      map: 'defaultmap/map',
      urbanismeStore: 'dossierpromoteur/getUrbanisme',
      progress_section: 'dossierpromoteur/getProgressSection',
      informationsStore: 'dossierpromoteur/getinfos',
    }),
  },
  created() {
    if (!this.api_zones_urbanisme_loaded) {
      this.updateZonesUrbaine(this.informationsStore).then((urba) => {
        this.urbanisme = urba
        this.initSelectedZone()
      })
    } else {
      this.urbanisme = _.merge({}, this.urbanisme, this.urbanismeStore)
      this.initSelectedZone()
    }

    if (!this.urbanisme.pdfs.length) {
      this.getPDFS()
    }
  },

  methods: {
    getPdsThroughApi() {
      let ids_parcelle = [
        this.informationsStore.current_parcelle,
        ...this.informationsStore.parcelles_voisines,
      ].map((parcelle) => parcelle.properties.id)
      this.$http
        .get('address', {
          params: {
            lon: this.informationsStore.center_parcelle_principale[0],
            lat: this.informationsStore.center_parcelle_principale[1],
            rayon_api_cadastre: 0,
            ids_parcelle: ids_parcelle,
            type: 'cadastre',
            details_urb: 1,
          },
        })
        .then((response) => {
          this.infos_cadastre.informations[0].pieces_ecrites =
            response.data.cadastre.informations[0].pieces_ecrites
          this.getPDFS()
        })
    },
    initSelectedZone() {
      this.selected_zone = this.urbanisme.all_zones_urba.length
        ? this.urbanisme.all_zones_urba[0].properties.libelle
        : null
    },
    changeCurrentZone(value) {
      this.selected_zone = value
    },
    getLiebelleZones() {
      return this.urbanisme.all_zones_urba.map(
        (zone) => zone.properties.libelle,
      )
    },
    getListZonesCrossApiCadastre() {
      return this.isExist(this.infos_cadastre.informations[0].zone_urba_obj)
        ? this.infos_cadastre.informations[0].zone_urba_obj
        : []
    },
    customFormatter(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    openPdfNewWindow(url) {
      window.open(url, '_blank')
    },
    getPDFS() {
      let pdfs_uploaded = this.urbanisme.pdfs.filter((pdf) => pdf.isUploaded)
      if (!_.isEmpty(this.infos_cadastre.informations[0].pieces_ecrites)) {
        let pieces_ecrites = this.infos_cadastre.informations[0].pieces_ecrites
        if (this.urbanisme.all_zones_urba.length) {
          this.urbanisme.pdfs = this.addDocsUrbanisme(
            this.urbanisme.all_zones_urba[0],
            pieces_ecrites,
          )
        } else {
          for (const [key, value] of Object.entries(pieces_ecrites)) {
            this.urbanisme.pdfs.push({
              name: this.shortenName(key),
              value: value,
              isShown: true,
              date: this.date(key),
              editText: false,
              isUploaded: false,
            })
          }
        }
      }
      this.urbanisme.pdfs.push(...pdfs_uploaded)
    },
    removeNameAndUnderscores(value) {
      return value
        .replace('.pdf', '')
        .replace(/_/g, ' ')
        .split(' ')
        .slice(1)
        .join(' ')
    },
    shortenName(value) {
      let newValue = value
      if (newValue.indexOf('.pdf')) {
        newValue = this.removeNameAndUnderscores(newValue)
        newValue = newValue.substring(0, newValue.lastIndexOf(' '))
      }
      return newValue ? newValue : 'Lien'
    },
    date(value) {
      let newValue = value
      if (newValue.indexOf('.pdf')) {
        newValue = this.removeNameAndUnderscores(newValue).split(' ').pop()
      }
      return this.customFormatter(newValue)
    },
    addFileToUrbanisme(file, pdf) {
      if (pdf['type'] === 'application/pdf') {
        this.urbanisme.pdfs.push({
          name: pdf.name,
          value: file,
          isShown: true,
          date: this.customFormatter(pdf.lastModified),
          editText: false,
          isUploaded: true,
        })
      } else {
        this.$toaster.warning('Veuillez importer un fichier pdf ')
      }
    },
    handleFileChange(file) {
      let vm = this
      const pdf = file.target.files[0]
      let reader = new FileReader()
      reader.onload = (f) => {
        vm.addFileToUrbanisme(f.target.result, pdf)
      }
      reader.readAsDataURL(pdf)
    },
    deleteAttribut(key) {
      this.urbanisme.all_zones_urba.splice(key, 1)
    },
    showPDFUpdate(item, index) {
      this.urbanisme.pdfs[index].editText = true
    },
    updatePDF(item, index) {
      this.urbanisme.pdfs[index].editText = false
    },
    deletePDF() {
      this.urbanisme.pdfs.splice(this.current_pdf_idx, 1)
      this.showModal = false
    },
    displayModal(idx) {
      this.current_pdf_idx = idx
      this.showModal = true
    },
    saveInfos() {
      this.$store.commit('dossierpromoteur/setUrbanisme', this.urbanisme)
    },
  },
  watch: {
    selected_zone(selected_zone) {
      this.current_zone = this.urbanisme.all_zones_urba.find(
        (zone) => zone.properties.libelle === selected_zone,
      )
    },
    infos_cadastre: {
      immediate: true,
      handler(value) {
        if (value) {
          for (const [key, value] of Object.entries(
            value.informations[0].pieces_ecrites,
          )) {
            this.piecesEcrites.push({
              name: this.shortenName(key),
              value: value,
              isShown: true,
              date: this.date(key),
            })
          }
        }
      },
    },
    urbanisme: {
      deep: true,
      handler(value) {
        if (value.ces) {
          this.$store.commit('dossierpromoteur/setCesPBP', value.ces)
        }
        let obj_keys = [value, 'pup', 'abf']
        if (this.urbanisme.all_zones_urba.length) {
          let keys_zone = [
            'libelle',
            'libelong',
            'hauteur',
            'ces',
            'cpt',
            'stationement',
            'mixite_social',
            'bande_principale',
            'bande_secondaire',
            'imple_vole',
            'impl_limite_separative',
            'impl_batiment',
            'caractere_zone',
            'espace_vert',
          ]

          this.urbanisme.all_zones_urba.forEach((zone, index) => {
            keys_zone.forEach((key) => {
              obj_keys.push(`all_zones_urba_${index}_properties_${key}`)
            })
          })
        }

        if (this.urbanisme.show_docs == 'Non') {
          const articles_keys = ['title', 'text']

          this.urbanisme.articles_plu.forEach((article, index) => {
            articles_keys.forEach((key) => {
              obj_keys.push(`articles_plu_${index}_${key}`)
            })
          })
        }
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'urbanisme',
          value: this.progressSection(...obj_keys),
        })
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveInfos()
    next()
  },
}
</script>
